import React, { useState, useEffect, useContext, Fragment } from "react";
import { jsx } from "@emotion/core";
import Header from "../components/Header";
import useTranslations from "../components/hooks/useTranslations";
import LocalizedLink from "../components/localizedLink";
import { LocaleContext } from "../context/locale-context";
import { rhythm, scale } from "../utils/typography";
import { mq } from "../utils/helper";
import useWindowSize from "../components/hooks/useWindowSize";
import SEO from "../components/seo";

function PageTitle({ isMobile }) {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();
  return (
    <div
      css={{
        backgroundImage: 'url("/assets/background-gray.svg")',
        paddingTop: rhythm(2)
      }}
    >
      <div
        css={{
          display: isMobile ? "block" : "grid",
          gridTemplateColumns: "1fr 2fr",
          gridColumnGap: rhythm(2),
          maxWidth: "70%",
          margin: `0 auto`,
          direction: isRTL ? "rtl" : "ltr"
        }}
      >
        <div>
          <h1
            css={{
              [mq[0]]: {
                fontSize: "2rem",
                margin: "0.5rem"
              }
            }}
          >
            {tr("Investigations")}
          </h1>
          <div
            css={{
              borderBottom: "3px solid #B32C50",
              width: "60%",
              marginBottom: rhythm(0.6)
            }}
          ></div>
          <em
            css={{
              textTransform: "uppercase"
            }}
          ></em>
        </div>
        <div
          css={{
            fontSize: "1.4rem",
            [mq[0]]: {
              fontSize: "1rem",
              marginTop: "1rem"
            }
          }}
        >
          {isRTL || (
            <Fragment>
              <p>
                This section provides an overview of four attacks on medical
                facilities (including hospitals and health care centers) that
                have taken place during the conflict in Yemen. This was
                determined by conducting an analysis of primary sources - such
                as video footage we have verified and witness statements, as
                well as secondary sources (e.g., reports by international human
                rights organisations and media).
              </p>
              <p>
                Conclusions drawn for each incident were reached through an
                analysis of video documentation, official reports and witness
                statements. By examining a variety of sources of information for
                each attack, the Yemeni Archive was able to corroborate and
                strengthen the findings from the database of visual content.
                Visual content gathered and verified by the Yemeni Archive is
                extensively analysed - including in-depth geolocation and, when
                relevant, munition identification.
              </p>
            </Fragment>
          )}
          {isRTL && (
            <Fragment>
              <p>
                يقدم هذا القسم لمحة عامة عن أربع هجمات على المرافق الطبية (بما
                في ذلك المستشفيات ومراكز الرعاية الصحية) التي وقعت أثناء النزاع
                في اليمن. تم تحديد ذلك من خلال إجراء تحليل للمصادر الأولية - مثل
                لقطات الفيديو التي تم التحقق منها وإفادات الشهود، فضلاً عن
                المصادر الثانوية (مثل تقارير المنظمات الدولية لحقوق الإنسان
                ووسائل الإعلام).
              </p>
              <p>
                تم الوصول إلى الاستنتاجات التي تم التوصل إليها لكل حادث من خلال
                تحليل وثائق الفيديو والتقارير الرسمية وبيانات الشهود. من خلال
                فحص مجموعة متنوعة من مصادر المعلومات لكل هجوم، تمكن الأرشيف
                اليمني من تأكيد وتعزيز النتائج من خلال قاعدة بيانات المحتوى
                المرئي. يتم تحليل المحتوى المرئي الذي تم جمعه والتحقق منه من قبل
                الأرشيف اليمني على نطاق واسع - بما في ذلك تحديد الإحداثيات
                الجغرافية المفصلة، وعند الاقتضاء، تحديد نوع ومصدر الذخيرة.
              </p>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

function Card({ node }) {
  const { title, image, desc, date, link } = node;
  const breakpoints = [576, 768, 992, 1200];
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();
  return (
    <div
      css={{
        [mq[0]]: {
          maxWidth: "90%",
          margin: "0 auto"
        }
      }}
    >
      <img
        src={image}
        css={{
          objectFit: "cover",
          width: "280px",
          height: "160px",
          marginBottom: rhythm(1 / 2)
        }}
        alt="missing pic"
      />
      <h3
        css={{
          ...scale(1 / 5),
          fontWeight: "bold",
          margin: 0
        }}
      >
        <a href={link}> {tr(title)}</a>
      </h3>
      <p
        css={{
          marginBottom: rhythm(0.5)
        }}
      >
        {tr(desc)}
      </p>
      <small
        css={{
          color: "#B32C50",
          fontWeight: "bold"
        }}
      >
        {date}
      </small>
    </div>
  );
}

function InvesGrid({ isMobile }) {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();
  const posts = [
    {
      title: "Targeting Kitaf Rural Hospital",
      date: "26/03/2019",
      image: "/assets/inve02.png",
      desc: "To be released soon",
      link: "#",
      id: 0
    },
    {
      title: "22 May Hospital Under Attack",
      date: "26/06/2019",
      image: "/assets/inve04.png",
      desc: "To be released soon",
      link: "#",
      id: 2
    },
    {
      title: "Airstrikes on Dar Al Shifa Medical Centre",
      date: "22/01/2018",
      image: "/assets/inve03.png",
      desc:
        "Alleged Saudi-led coalition airstrike killed and wounded patients at the Dar Al Shifa Hospital",
      link: `https://yemeniarchive.org/${locale}/investigations/daralshifa.html`,
      id: 1
    },

    {
      title: "Systematic Attacks on Al-Thawra Hospital in Taiz",
      date: "20/06/2015",
      image: "/assets/inve01.png",
      desc: "Targeting Al-Thawra Hospital in Taiz City",
      link: `https://yemeniarchive.org/${locale}/investigations/taizalthawra.html`,
      id: 3
    }
  ];
  return (
    <div
      css={{
        display: isMobile ? "block" : "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gridRowGap: rhythm(6.2),
        gridColumnGap: rhythm(1),
        marginTop: rhythm(4.2),
        paddingBottom: rhythm(4.2),
        maxWidth: "90%",
        margin: `${rhythm(2)} auto`,
        direction: isRTL ? "rtl" : "ltr"
      }}
    >
      {posts.map(node => (
        <Card node={node} key={node.id} />
      ))}
    </div>
  );
}

function Investigations() {
  const [windowWidthSize, windowHeightSize] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    windowWidthSize < 992 ? setIsMobile(true) : setIsMobile(false);
  }, [windowWidthSize]);
  return (
    <div>
      <SEO />
      <Header />
      <PageTitle isMobile={isMobile} />
      <InvesGrid isMobile={isMobile} />
    </div>
  );
}

export default Investigations;
